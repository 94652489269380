import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { ApiErrorResponse, ErrorHandlerV2Service, SelectOption } from '@gea/digital-ui-lib';
import { catchError, filter, first, map, of, tap } from 'rxjs';

import { MachinesApiService } from '@shared/services';
import { MachineAndFaultDto, MachineOverviewModel, MachinesStateModel } from '@shared/models';
import { ClearMachines, FetchMachines, SetMachineOverviewData, SetSelectedMachines } from './machines.action';
import { FaultsState, FetchFaultCodes } from '../faults';

@State<MachinesStateModel>({
  name: 'machines',
  defaults: {
    machines: [],
    selected: [],
    machineAndFaults: [],
    machineOverviewTableData: [],
  },
})
@Injectable()
export class MachinesState {
  constructor(
    private machinesApiService: MachinesApiService,
    private errorHandler: ErrorHandlerV2Service,
    private store: Store
  ) {}

  @Selector()
  static machines(state: MachinesStateModel): SelectOption[] {
    return state.machines;
  }

  @Selector()
  static selected(state: MachinesStateModel): number[] {
    return state.selected;
  }

  @Selector()
  static selectedMachine(state: MachinesStateModel): number {
    return state.selected[0];
  }

  @Selector()
  static machineAndFaults(state: MachinesStateModel): MachineAndFaultDto[] {
    return state.machineAndFaults;
  }

  @Selector()
  static machineOverviewData(state: MachinesStateModel): MachineOverviewModel[] {
    return state.machineOverviewTableData;
  }

  @Action(FetchMachines)
  fetchMachines({ patchState }: StateContext<MachinesStateModel>) {
    return this.machinesApiService.fetchMachines().pipe(
      filter((m) => m.length > 0),
      catchError((error: ApiErrorResponse) => {
        this.errorHandler.handleError(error);
        return of([]);
      }),
      first(),
      map((machines: MachineAndFaultDto[]) => {
        patchState({
          machineAndFaults: machines,
        });
        return machines.map((machine) => ({
          name: machine.name,
          value: machine.id,
        }));
      }),
      tap((machines: SelectOption<number>[]) => {
        patchState({
          machines,
        });
      })
    );
  }

  @Action(SetSelectedMachines)
  setSelectedMachines({ patchState, dispatch }: StateContext<MachinesStateModel>, { machines }: SetSelectedMachines) {
    patchState({
      selected: machines,
    });

    if (machines.length) {
      dispatch(new FetchFaultCodes(machines));
    }
  }

  @Action(ClearMachines)
  clearMachines({ patchState }: StateContext<MachinesStateModel>) {
    return of(
      patchState({
        machines: [],
        machineAndFaults: [],
        machineOverviewTableData: [],
        selected: [],
      })
    );
  }

  @Action(SetMachineOverviewData)
  setMachineOverviewData({ patchState }: StateContext<MachinesStateModel>) {
    const machines = this.store.selectSnapshot(MachinesState.machineAndFaults);
    const warnings = this.store.selectSnapshot(FaultsState.warnings);
    const shutdowns = this.store.selectSnapshot(FaultsState.shutdowns);
    const machineOverViewList: MachineOverviewModel[] = [];

    for (const machine of machines) {
      const machineOverviewModel: MachineOverviewModel = {
        machine: { id: machine.id, name: machine.name, nameInCRM: machine.nameInCRM },
        warnings: warnings.filter((w) => w.machineId === machine.id).length ?? 0,
        shutdowns: shutdowns.filter((s) => s.machineId === machine.id).length ?? 0,
      };
      machineOverViewList.push(machineOverviewModel);
    }

    patchState({
      machineOverviewTableData: machineOverViewList,
    });
  }
}
