<div class="resolved-fault-detail-dialog">
  <div mat-dialog-title class="resolved-fault-detail-dialog-header">
    <gea-hrt-heading class="dialog-title" type="sub">
      <h2>{{ 'FAULT_RECOMMENDATIONS.SOLVED_FAULTS.PROBLEM_SOLVING' | translate }}: {{ data.customer }}</h2>
    </gea-hrt-heading>
    <gea-hrt-close-dialog-button (closeDialog)="closeDialog()" />
  </div>

  <div class="sub-header">
    <p>
      <strong> {{ 'FAULT_RECOMMENDATIONS.SOLVED_FAULTS.CUSTOMER' | translate }}: </strong>
      <span>{{ data.customer }}</span>
    </p>
    <p>
      <strong> {{ 'FAULT_RECOMMENDATIONS.SOLVED_FAULTS.MACHINE' | translate }}: </strong>
      <span>{{ data.machineName }}</span>
    </p>
  </div>

  <mat-dialog-content>
    <div *ngIf="data.selectedRecommendations.length" class="section">
      <h3>Selected Recommendations</h3>
      <div *ngFor="let recommendation of data.selectedRecommendations; let i = index" class="selected">
        <label for="i">
          <input [id]="i" type="checkbox" checked disabled />
          {{ recommendation.title }}
        </label>
      </div>
    </div>
    <div class="section">
      <h3>Additional note</h3>
      <p>{{ data.additionalNote }}</p>
    </div>
  </mat-dialog-content>
  <div class="buttons">
    <geaui-button-v2
      type="primary"
      (click)="setReviewStatus('useful')"
      data-testid="btn-set-useful"
      [disabled]="data.status === 'useful' || formGroup.controls.additionalNote.value.length < 10">
      {{ 'FAULT_RECOMMENDATIONS.SOLVED_FAULTS.USEFUL' | translate }}
    </geaui-button-v2>
    <geaui-button-v2
      [disabled]="data.status === 'discarded'"
      type="secondary"
      class="discard"
      (click)="setReviewStatus('discarded')"
      data-testid="btn-discard">
      {{ 'FAULT_RECOMMENDATIONS.SOLVED_FAULTS.DISCARD' | translate }}
    </geaui-button-v2>
  </div>
</div>
