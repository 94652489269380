import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MachineOverviewModel } from '@shared/models';
import { ColumnRendererComponent, IconModule, TableServiceV2 } from '@gea/digital-ui-lib';
import { EDIT_MACHINE_ACTION } from '@tab-container/machine-overview/table-config/machine-column-definitions.config';
import { TooltipModule } from 'primeng/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'gea-hrt-edit-machine-renderer',
  standalone: true,
  imports: [CommonModule, IconModule, TooltipModule, TranslateModule],
  templateUrl: './edit-machine-renderer.component.html',
  styleUrl: './edit-machine-renderer.component.scss',
})
export class EditMachineRendererComponent implements OnInit, ColumnRendererComponent<MachineOverviewModel> {
  rowData?: MachineOverviewModel;
  tableId?: string;
  private tableV2Service: TableServiceV2 = inject(TableServiceV2);

  ngOnInit(): void {
    if (!this.rowData) return;
  }

  onFocusOut(inputField: HTMLInputElement) {
    const oldMachineName = this.rowData?.machine.name;
    const text = inputField.value;
    if (!this.tableId || !oldMachineName) return;
    if (!text) {
      inputField.value = oldMachineName;
    }
    if (oldMachineName !== inputField.value) {
      const newMachineData = { ...this.rowData, machine: { ...this.rowData?.machine, name: text } };
      this.tableV2Service.publishAction(this.tableId, EDIT_MACHINE_ACTION, newMachineData);
    }
  }

  onSetMachineNameCRM(inputField: HTMLInputElement) {
    const machineNameCRM = this.rowData?.machine.nameInCRM;
    if (!inputField || !machineNameCRM || !this.tableId) return;
    if (inputField.value === machineNameCRM) return;

    inputField.value = machineNameCRM;
    const newMachineData = { ...this.rowData, machine: { ...this.rowData?.machine, name: machineNameCRM } };
    this.tableV2Service.publishAction(this.tableId, EDIT_MACHINE_ACTION, newMachineData);
  }
}
