import { EditMachineRendererComponent } from '@tab-container/machine-overview/table-config/edit-machine-renderer/edit-machine-renderer.component';
import { MachineOverviewModel } from '@shared/models';
import { ColumnRendererComponent } from '@gea/digital-ui-lib';

export const EDIT_MACHINE_ACTION = 'edit-machine';

export const machineColumnDefinitionsConfig = [
  {
    displayName: 'MACHINE_OVERVIEW.TABLE_HEADERS.MACHINE_NAME',
    key: 'machines.machineName',
    sortable: true,
    width: 500,
    renderer: {
      component: EditMachineRendererComponent as ColumnRendererComponent<MachineOverviewModel>,
    },
  },
  {
    displayName: 'MACHINE_OVERVIEW.TABLE_HEADERS.SHOUT_DOWNS',
    key: 'shutdowns',
    sortable: false,
  },
  {
    displayName: 'MACHINE_OVERVIEW.TABLE_HEADERS.WARNINGS',
    key: 'warnings',
    sortable: false,
  },
];
