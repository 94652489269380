import { Injectable } from '@angular/core';

import { HrtApiService } from './hrt-api.service';
import { SelectedRecommendation } from '@shared/models';

@Injectable({
  providedIn: 'root',
})
export class ProblemSolvingApiService {
  problemSolvingUrl = 'machines/{machine}/faults/{fault}/problemSolving';

  constructor(private api: HrtApiService) {}

  solveProblem({
    machine,
    faultCode,
    selectedRecommendations,
    additionalNote,
    images,
  }: {
    machine: number;
    faultCode: number;
    selectedRecommendations?: SelectedRecommendation[];
    additionalNote?: string;
    images: (string | ArrayBuffer[])[];
  }) {
    const url = this.problemSolvingUrl.replace('{machine}', machine.toString()).replace('{fault}', faultCode.toString());
    return this.api.postForOrganization(url, { selectedRecommendations, additionalNote, images });
  }
}
