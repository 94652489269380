import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'gea-hrt-empty-data-placeholder',
  templateUrl: './empty-data-placeholder.component.html',
  styleUrls: ['./empty-data-placeholder.component.scss'],
})
export class EmptyDataPlaceholderComponent {
  @Input({ required: true }) text!: string;

  constructor(private translate: TranslateService) {}
}
