<div class="fault-recommendations-dialog-container">
  <mat-icon class="close-icon" (click)="closeDialog()" svgIcon="close" color="grey"></mat-icon>
  <mat-dialog-content>
    <div class="gallery">
      <div class="main-image">
        <img [src]="'data:image/png;base64,' + currentImage" alt="Preview" class="preview-image" />
      </div>
      <div class="thumbnails">
        <div *ngFor="let image of data.images; let i = index; trackBy: trackByImage" class="carousel-slide current thumbnail">
          <img [src]="'data:image/png;base64,' + image" alt="Preview" class="preview-image" (click)="setCurrentImage(image)" />
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>
