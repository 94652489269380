import { FaultComparator } from './fault.comparator';
import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ApiErrorResponse, ErrorHandlerV2Service, SelectOption } from '@gea/digital-ui-lib';
import { catchError, first, of } from 'rxjs';

import { DateHelper } from '@shared/helpers';
import {
  ClearFaults,
  FetchFaultAnalysis,
  FetchFaultCodes,
  FetchFaultCodesForMachine,
  ResetFaultAnalysis,
  SetSelectedFaultCodeGroups,
  SetSelectedFaultCodes,
  SortShutdowns,
  SortWarnings,
} from './faults.action';
import { FaultsApiService } from '@shared/services';
import { FaultAnalysisHistoryItem, Shutdown, Warning, FaultAnalysisDto, MachineFaultDto, SelectableModel } from '@shared/models';

const DEFAULT_DATE_RANGE = DateHelper.daysBack(28);

export interface FaultsStateModel extends SelectableModel {
  faultyMachines: SelectOption<number>[];
  faultCodes: SelectOption[];
  faultCodeGroups: SelectOption<string>[];
  shutdowns: MachineFaultDto[];
  warnings: MachineFaultDto[];
  faultHistory: FaultAnalysisHistoryItem[];
  selectedGroups: number[];
}

@State<FaultsStateModel>({
  name: 'faults',
  defaults: {
    faultyMachines: [],
    faultCodes: [],
    faultCodeGroups: [],
    shutdowns: [],
    warnings: [],
    selected: [],
    selectedGroups: [],
    faultHistory: [],
  },
})
@Injectable()
export class FaultsState {
  constructor(
    private faultsApi: FaultsApiService,
    private errorHandler: ErrorHandlerV2Service
  ) {}

  @Selector()
  static faultyMachines(state: FaultsStateModel): SelectOption<number>[] {
    return state.faultyMachines;
  }

  @Selector()
  static faultCodes(state: FaultsStateModel): SelectOption[] {
    return state.faultCodes;
  }

  @Selector()
  static faultCodeGroups(state: FaultsStateModel): SelectOption[] {
    return state.faultCodeGroups;
  }

  @Selector()
  static selected(state: FaultsStateModel): number[] {
    return state.selected;
  }

  @Selector()
  static selectedGroups(state: FaultsStateModel): number[] {
    return state.selectedGroups;
  }

  @Selector()
  static shutdowns(state: FaultsStateModel): Shutdown[] {
    return state.shutdowns;
  }

  @Selector()
  static warnings(state: FaultsStateModel): Warning[] {
    return state.warnings;
  }

  @Selector()
  static annunciations(state: FaultsStateModel): MachineFaultDto[] {
    return [...state.warnings, ...state.shutdowns];
  }

  @Selector()
  static faultHistory(state: FaultsStateModel): FaultAnalysisHistoryItem[] {
    return state.faultHistory;
  }

  @Action(FetchFaultCodes)
  fetchFaultCodes({ patchState }: StateContext<FaultsStateModel>, action: FetchFaultCodes) {
    this.faultsApi
      .fetchFaultCodes(action.machines, action.dateRange ?? DEFAULT_DATE_RANGE, action.status)
      .pipe(
        catchError((error: ApiErrorResponse) => {
          if (error) {
            this.errorHandler.handleError(error);
          }
          const faultAnalysis: FaultAnalysisDto = {
            machines: [],
            shutdowns: [],
            warnings: [],
            faultHistory: [],
          };

          return of({ faultCodes: [], faultCodeGroups: [], faultAnalysis });
        }),
        first()
      )
      .subscribe(({ faultCodes, faultCodeGroups, faultAnalysis }) => {
        let { shutdowns, warnings } = faultAnalysis;

        const faultyMachines = faultAnalysis.machines.map(({ name, id: value }) => ({ name, value }));
        shutdowns = [...shutdowns].sort(FaultComparator.by('totalPending', 'desc'));
        warnings = [...warnings].sort(FaultComparator.by('totalPending', 'desc'));

        patchState({
          ...faultAnalysis,
          faultCodes,
          faultCodeGroups,
          faultyMachines,
          shutdowns,
          warnings,
        });
      });
  }

  @Action(FetchFaultCodesForMachine)
  fetchFaultCodesForMachine({ patchState }: StateContext<FaultsStateModel>, action: FetchFaultCodesForMachine) {
    this.faultsApi
      .fetchFaultCodesForMachine(action.machine)
      .pipe(
        catchError((error: ApiErrorResponse) => {
          if (error) {
            this.errorHandler.handleError(error);
          }
          return of([] as SelectOption[]);
        }),
        first()
      )
      .subscribe((faultCodes) => {
        patchState({
          faultCodes,
        });
      });
  }

  @Action(FetchFaultAnalysis)
  fetchFaultAnalysis({ patchState }: StateContext<FaultsStateModel>, action: FetchFaultAnalysis) {
    this.faultsApi
      .fetchFaultAnalysis(action.machines, action.dateRange || DEFAULT_DATE_RANGE, action.faultCodeGroups, action.status)
      .pipe(
        catchError((error: ApiErrorResponse) => {
          if (error) {
            this.errorHandler.handleError(error);
          }
          return of({
            machines: [],
            shutdowns: [],
            warnings: [],
            faultHistory: [],
          } as FaultAnalysisDto);
        }),
        first()
      )
      .subscribe((faultAnalysis: FaultAnalysisDto) => {
        const faultyMachines = faultAnalysis.machines.map(({ name, id: value }) => ({ name, value }));
        let { shutdowns, warnings } = faultAnalysis;

        shutdowns = [...shutdowns].sort(FaultComparator.by('totalPending', 'desc'));
        warnings = [...warnings].sort(FaultComparator.by('totalPending', 'desc'));

        patchState({ ...faultAnalysis, faultyMachines, shutdowns, warnings });
      });
  }

  @Action(ClearFaults)
  clearFaults({ patchState }: StateContext<FaultsStateModel>) {
    patchState({
      faultCodes: [],
      faultCodeGroups: [],
      shutdowns: [],
      warnings: [],
      faultHistory: [],
      selected: [],
    });
  }

  @Action(ResetFaultAnalysis)
  resetFaultAnalysis({ patchState }: StateContext<FaultsStateModel>) {
    patchState({
      shutdowns: [],
      warnings: [],
      faultHistory: [],
      selected: [],
    });
  }

  @Action(SetSelectedFaultCodes)
  setSelectedFaultCodes({ patchState }: StateContext<FaultsStateModel>, { faultCodes }: SetSelectedFaultCodes) {
    patchState({
      selected: faultCodes,
    });
  }

  @Action(SetSelectedFaultCodeGroups)
  setSelectedFaultCodeGroups({ patchState }: StateContext<FaultsStateModel>, action: SetSelectedFaultCodeGroups) {
    if (action.faultCodeGroups) {
      patchState({
        selectedGroups: action.faultCodeGroups,
      });
    }
  }

  @Action(SortShutdowns)
  sortShutdowns({ patchState, getState }: StateContext<FaultsStateModel>, action: SortShutdowns) {
    const shutdownList = [...getState().shutdowns];
    shutdownList.sort(FaultComparator.by(action.columName, action.sortOrder));
    if (shutdownList) {
      patchState({
        shutdowns: shutdownList,
      });
    }
  }

  @Action(SortWarnings)
  sortWarnings({ patchState, getState }: StateContext<FaultsStateModel>, action: SortWarnings) {
    const warningsList = [...getState().warnings];
    warningsList.sort(FaultComparator.by(action.columName, action.sortOrder));
    if (warningsList) {
      patchState({
        warnings: warningsList,
      });
    }
  }
}
