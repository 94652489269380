import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Store } from '@ngxs/store';
import { EMPTY, catchError, first, of } from 'rxjs';

import { environment } from '@environments/environment';
import { MsalBroadcastService } from '@azure/msal-angular';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class DeveloperApiService extends ApiService {
  constructor(
    @Inject('hrtFaultDatabaseBaseUrl') protected baseUrl: string,
    @Inject('hrtFaultDatabaseSubscriptionKey') protected subscriptionKey: string,
    protected msalBroadcast: MsalBroadcastService,
    protected http: HttpClient,
    protected store: Store
  ) {
    super(baseUrl, subscriptionKey, msalBroadcast, http, store);
    if (!environment.production) {
      Object.defineProperty(window, 'resetData', {
        // eslint-disable-next-line  @typescript-eslint/no-unsafe-assignment
        value: this.resetDataInstantly.bind(this),
      });
    }
  }

  resetData() {
    return this.put<void>('/faultdatabase/setReset', null);
  }
  resetDataInstantly() {
    /* eslint-disable no-restricted-syntax,no-console */
    console.info('-- resetting database --');
    this.resetData()
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error('-- database reset failed --');
          console.error(error);
          return of(EMPTY);
        }),
        first()
      )
      .subscribe(() => {
        console.info('-- database reset successfully --');
      });
    /* eslint-enable no-restricted-syntax,no-console */
  }
}
