import { Environment } from '@gea/digital-ui-lib';
import { EnvironmentModel } from './model/environment.model';

export const environment: EnvironmentModel = {
  production: false,
  name: Environment.DEV,
  oAuth: {
    clientId: 'd03ef182-fce9-4c85-8c40-a3718b38988a',
    authority: 'https://login.portal.tst.gea.com/geaidtst.onmicrosoft.com/b2c_1_signin_signup/',
    authorityDomain: 'login.portal.tst.gea.com',
    postLogoutRedirectUri: 'https://insightpartner.blu-red-care.apps.dev.gea.com',
    redirectUri: 'https://insightpartner.blu-red-care.apps.dev.gea.com',
  },
  baseURL: 'https://api.apps.tst.gea.com/',
  subscriptionKey: '',
  hrt: {
    baseURL: 'https://api-totalcarepartner-dev.azure-api.net/frontend/v1/',
    subscriptionKey: '8929385dc1224974adf8cb732451023e',
  },
  portal: {
    baseURL: 'https://portal.tst.gea.com/'
  }
};
