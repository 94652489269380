import { MatIconRegistry } from '@angular/material/icon';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';

import { Store } from '@ngxs/store';
import { ImageService, SetEnvironment, UserState } from '@gea/digital-ui-lib';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '@environments/environment';
import { FetchMemberships } from '@shared/state';

@Component({
  selector: 'gea-hrt-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  contentReady = false;
  noAuth = true;
  firefox = false;

  constructor(
    public title: Title,
    private translate: TranslateService,
    private store: Store,
    private iconRegistry: MatIconRegistry,
    private readonly imageService: ImageService,
    private domSanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.firefox = window.navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    this.store.select(UserState.token).subscribe((token) => {
      this.contentReady = token !== '';
      if (this.contentReady) {
        this.store.dispatch(new FetchMemberships());
      }
    });
    if (environment.name) {
      this.store.dispatch(new SetEnvironment(environment.name));
    }

    this.translate.setDefaultLang('en-US');
    this.translate.get('GENERAL.TITLE').subscribe((appTitle: string) => {
      this.title.setTitle(appTitle);
    });
    this.addCustomIcons();
  }

  addCustomIcons() {
    const { iconRegistry, domSanitizer } = this;
    iconRegistry.addSvgIcon('blue-info', domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/blue-info.svg'));
    iconRegistry.addSvgIcon('close', domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/close.svg'));
    iconRegistry.addSvgIcon('close_black', domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/close_black.svg'));
    iconRegistry.addSvgIcon('information', domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/information.svg'));
    iconRegistry.addSvgIcon('check_green', domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/check_green.svg'));
    iconRegistry.addSvgIcon(
      'warning_triangle',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/images/warning_triangle.svg')
    );
    iconRegistry.addSvgIcon('warning_round', domSanitizer.bypassSecurityTrustResourceUrl('assets/images/warning_round.svg'));
    iconRegistry.addSvgIcon('check_white', domSanitizer.bypassSecurityTrustResourceUrl('assets/images/check_white.svg'));
    iconRegistry.addSvgIcon('fix_key', domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/fix_key.svg'));
    iconRegistry.addSvgIcon('reload', domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/reload.svg'));
    iconRegistry.addSvgIcon('mail', this.imageService.getMailIcon());
    iconRegistry.addSvgIcon('image', domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/image.svg'));
    iconRegistry.addSvgIcon('eye', domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/eye.svg'));
    iconRegistry.addSvgIcon(
      'scroll-vertical',
      domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/scroll-vertical.svg')
    );
    iconRegistry.addSvgIcon(
      'pending_shutdowns',
      domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/pending_shutdowns.svg')
    );
    iconRegistry.addSvgIcon(
      'pending_warnings',
      domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/pending_warnings.svg')
    );
    iconRegistry.addSvgIcon('plus', this.imageService.getPlusIcon());
    iconRegistry.addSvgIcon('pencil', domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/pencil.svg'));
    iconRegistry.addSvgIcon('trash-can', domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/trash-can.svg'));
  }
}
