<div class="fault-analysis" [ngClass]="layout.screenClasses | async">
  <div class="flex">
    <div class="filter">
      <geaui-multi-select-v2
        id="machines"
        [label]="'FAULT_ANALYSIS.MACHINE' | translate"
        [group]="formGroup"
        [selected]="selectedMachines"
        [options]="machineOptions"
        (clearButtonClick)="onSetSelectedMachines([])"
        (selectChanged)="onSetSelectedMachines($event)" />
    </div>
    <div class="filter">
      <geaui-select-v2
        id="dateRange"
        [label]="'FAULT_ANALYSIS.DATE_RANGE' | translate"
        [group]="formGroup"
        [options]="dateRangeOptions"
        [selected]="dateRange"
        (selectChanged)="onSetDateRange($event)" />
    </div>
    <div class="filter">
      <geaui-multi-select-v2
        id="status"
        [label]="'FAULT_ANALYSIS.FAULT_STATUS' | translate"
        [group]="formGroup"
        [options]="statusOptions"
        [selected]="statusOptions"
        (selectChanged)="onSetStatus($event)" />
    </div>
    <div class="filter">
      <geaui-multi-select-v2
        id="faultCodes"
        [label]="'FAULT_ANALYSIS.FAULT_NAME' | translate"
        [group]="formGroup"
        [options]="faultCodeOptions"
        [disabled]="!faultCodeOptions.length"
        [selected]="selectedFaultCodes"
        (clearButtonClick)="onSetSelectedFaultCodes([])"
        (selectChanged)="onSetSelectedFaultCodes($event)" />
    </div>
  </div>
</div>
