import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { RecommendationDto, RecommendationStatus } from '@shared/models';
import { DeleteRecommendationDialogComponent, RecommendationDialogComponent } from '@app/tab-container/fault-recommendations';
import { Subscription } from 'rxjs';
import { RecommendationImagesDialogComponent } from '@troubleshooting/recommendations';

export type RecommendationAction = { recommendationId: number; statusToSet: RecommendationStatus };

@Component({
  selector: 'gea-hrt-recommendations-list',
  templateUrl: './recommendations-list.component.html',
})
export class RecommendationsListComponent implements OnDestroy {
  Status = RecommendationStatus;

  @Input() isEditable = true;
  @Input() list: RecommendationDto[] = [];

  @Output() actionClicked = new EventEmitter<RecommendationAction>();
  @Output() updateRecommendation = new EventEmitter<RecommendationDto>();
  @Output() deleteRecommendation = new EventEmitter<RecommendationDto>();

  private closeDialog$ = new Subscription();

  constructor(private dialog: MatDialog) {}

  ngOnDestroy() {
    this.closeDialog$.unsubscribe();
  }

  openEditRecommendationDialog(recommendation: RecommendationDto) {
    const dialogRef = this.dialog.open(RecommendationDialogComponent, {
      minWidth: '50vw',
      minHeight: '50vh',
      autoFocus: true,
    });

    const formGroup = dialogRef.componentInstance.formGroup;
    formGroup.controls['recommendation'].patchValue(recommendation.actionName);

    this.closeDialog$.add(
      dialogRef.afterClosed().subscribe((data: { actionName?: string; images: [] }) => {
        if (data?.actionName) {
          const images = recommendation.images === data.images ? recommendation.images : data.images;
          this.saveRecommendation({ ...recommendation, ...data, images });
        }
      })
    );
  }

  setRecommendationUseful(recommendation: RecommendationDto) {
    this.saveRecommendation(recommendation);
  }

  openDeleteRecommendationDialog(recommendation: RecommendationDto) {
    const dialogRef = this.dialog.open(DeleteRecommendationDialogComponent);
    dialogRef.componentInstance.recommendation = recommendation;

    this.closeDialog$.add(
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.deleteRecommendation.emit(recommendation);
        }
      })
    );
  }

  openImagesDialog(images: (ArrayBuffer[] | string)[]) {
    this.dialog.open(RecommendationImagesDialogComponent, {
      data: { images },
    });
  }

  trackByRecommendationId(index: number, item: RecommendationDto): number {
    return item.id;
  }

  private saveRecommendation(recommendation: RecommendationDto) {
    this.updateRecommendation.emit(recommendation);
  }
}
