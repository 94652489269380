import { MatDialog } from '@angular/material/dialog';
import { Component, Input, OnDestroy, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ProblemSolveDialogComponent } from './solve-dialog';
import { RecommendationDto, RecommendationStatus, ProblemResolveDto } from '@shared/models';
import { ContactDialogComponent } from './contact-dialog/contact-dialog.component';
import { Store } from '@ngxs/store';
import { PermissionState } from '@shared/state';

@Component({
  selector: 'gea-hrt-troubleshooting-problem-solve',
  templateUrl: './problem-solve.component.html',
  styleUrls: ['./problem-solve.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProblemSolveComponent implements OnDestroy {
  permission$!: Observable<
    | 'GEA_App_Administrator'
    | 'GEA_App_Operator'
    | 'gea_read_only'
    | 'customer'
    | 'customerAdmin'
    | 'admin'
    | 'maintenance_manager'
    | 'technician'
    | 'executive_board'
  >;
  constructor(
    private readonly dialog: MatDialog,
    private store: Store
  ) {
    this.permission$ = this.store.select(PermissionState.permission);
  }

  @Input() showText = true;

  @Input({ required: true }) list: RecommendationDto[] = [];

  @Output() emitProblemSolved = new EventEmitter<ProblemResolveDto | undefined>();

  closeDialog$!: Subscription;

  ngOnDestroy() {
    if (this.closeDialog$) {
      this.closeDialog$.unsubscribe();
    }
  }

  problemSolvedClicked() {
    if (this.list.some(({ status }) => status === RecommendationStatus.DONE)) {
      this.emitProblemSolved.emit();
      return;
    }
    this.openSolutionDescriptionDialog();
  }

  openContactDialog() {
    const dialog = this.dialog.open(ContactDialogComponent, {
      width: '100rem',
      minHeight: '50vh',
    });

    // TODO: send the information to Felix per E-mail
    this.closeDialog$ = dialog.afterClosed().subscribe(() => {});
  }

  openSolutionDescriptionDialog() {
    const dialog = this.dialog.open(ProblemSolveDialogComponent, {
      width: '100rem',
      minHeight: '50vh',
    });

    dialog.componentInstance.recommendations = this.list;

    this.closeDialog$ = dialog.afterClosed().subscribe((props: ProblemResolveDto) => {
      if (!props) {
        // user closed/canceled the modal -> do nothing
        return;
      }
      this.emitProblemSolved.emit(props);
    });
  }
}
