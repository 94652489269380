import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ProblemSolveDialogComponent } from '../solve-dialog/solve-dialog.component';

@Component({
  selector: 'gea-hrt-app-contact-dialog',
  templateUrl: './contact-dialog.component.html',
  styleUrls: ['./contact-dialog.component.scss'],
})
export class ContactDialogComponent {
  formGroup: FormGroup;

  constructor(private dialogRef: MatDialogRef<ProblemSolveDialogComponent>) {
    const { email, required, minLength, maxLength, pattern } = Validators;

    this.formGroup = new FormGroup({
      email: new FormControl('', [required, email, minLength(10), maxLength(100), pattern(/^\S+@\S+\.\S+$/)]),
      phone: new FormControl('', [required, minLength(4), pattern(/^[-+()0-9]+/)]),
      title: new FormControl('', [required, minLength(10), maxLength(100)]),
      message: new FormControl('', [required, minLength(10), maxLength(1000)]),
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  save() {
    this.dialogRef.close(this.formGroup.value);
  }
}
