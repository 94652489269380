export class FetchRelatedOperatingData {
  static readonly type = '[Troubleshooting] Fetch Related Operating Data';
  constructor(
    public readonly machine: number,
    public readonly faultCode: number,
    public readonly dateHours?: number,
    public readonly temperatureUnit?: string,
    public readonly pressureUnit?: string
  ) {}
}
