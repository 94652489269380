import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { EventType } from '@azure/msal-browser';
import { MsalBroadcastService } from '@azure/msal-angular';
import { BehaviorSubject, concatMap, filter, first, Observable, switchMap, tap } from 'rxjs';

import { Store } from '@ngxs/store';
import { UserState } from '@gea/digital-ui-lib';

// from httpClient'S get method
type HttpOptions = {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  context?: HttpContext;
  observe?: 'body';
  params?:
    | HttpParams
    | {
        [param: string]: string | number | boolean | Readonly<string | number | boolean>[];
      };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
};

type BlobHttpOptions = {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  context?: HttpContext;
  observe?: 'body';
  params?:
    | HttpParams
    | {
        [param: string]: string | number | boolean | Readonly<string | number | boolean>[];
      };
  reportProgress?: boolean;
  responseType: 'blob';
  withCredentials?: boolean;
};

const CAUGHT_EVENTS: EventType[] = [
  EventType.ACQUIRE_TOKEN_START,
  EventType.ACQUIRE_TOKEN_SUCCESS,
  EventType.ACQUIRE_TOKEN_FAILURE,
];

/**
 * use this service instead of the httpClient to make sure
 * some basic headers are already taken care of.
 */
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private _idToken: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private idToken$: Observable<string> = this._idToken.asObservable();

  private _afterRefreshed: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private afterRefreshed$: Observable<boolean> = this._afterRefreshed.asObservable();

  private _expirationTime: BehaviorSubject<number> = new BehaviorSubject(0);
  private expirationTime$: Observable<number> = this._expirationTime.asObservable();

  constructor(
    @Inject('baseUrl') protected baseUrl: string,
    @Inject('subscriptionKey') protected subscriptionKey: string | undefined,
    protected msalBroadcast: MsalBroadcastService,
    protected http: HttpClient,
    protected store: Store
  ) {
    // always get the latest token
    this.store
      .select(UserState.token)
      .pipe(filter((token) => token !== ''))
      .subscribe((idToken: string) => this._idToken.next(idToken));

    // while the token is being refreshed, allow deferring any calls
    this.msalBroadcast.msalSubject$
      .pipe(
        filter(({ eventType }) => CAUGHT_EVENTS.includes(eventType)),
        tap(({ eventType }) => {
          this._afterRefreshed.next(eventType === EventType.ACQUIRE_TOKEN_START);
        })
      )
      .subscribe();

    // get expiration time to avoid edge cases where fetching the new token starts just as a request is being made
    this.store.select(UserState.expirationTime).subscribe((time) => {
      this._expirationTime.next(time || 0);
    });
  }

  /**
   * a wrapper for the httpClient's GET method
   * - sets some basic headers
   * @param url the part of the url after the baseUrl
   * @param options object containing extra HTTP Headers or params
   * @param apiVersion usually the same for all apis defaults to "1"
   */
  get<T>(url: string, options: HttpOptions = {}, apiVersion?: string): Observable<T> {
    return this.getToken().pipe(
      concatMap((idToken) => {
        const headers = {
          ...this.getBaseHeaders(idToken),
          ...(apiVersion ? { 'Api-Version': apiVersion } : {}),
        };
        options.headers = { ...headers, ...(options.headers || {}) };
        return this.http.get<T>(this.baseUrl + url, options);
      })
    );
  }

  getBlob<Blob>(url: string, options: BlobHttpOptions, apiVersion?: string): Observable<Blob> {
    return this.getToken().pipe(
      concatMap((idToken) => {
        const headers = {
          ...this.getBaseHeaders(idToken),
          ...(apiVersion ? { 'Api-Version': apiVersion } : {}),
        };
        options.headers = { ...headers, ...(options.headers || {}) };
        return this.http.get(this.baseUrl + url, options) as Observable<Blob>;
      })
    );
  }

  /**
   * a wrapper for the httpClient's POST method
   * - sets some basic headers
   * @param url the part of the url after the baseUrl
   * @param payload the data to be sent to the backend
   * @param options object containing extra HTTP Headers or params
   * @param apiVersion usually the same for all apis defaults to "1"
   */
  // to support multiple types of payloads, we use "any" here
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  post<T>(url: string, payload: any, options: HttpOptions = {}, apiVersion?: string): Observable<T> {
    return this.getToken().pipe(
      switchMap((idToken) => {
        const headers = {
          ...this.getBaseHeaders(idToken),
          ...this.getPostHeaders(),
          ...(apiVersion ? { 'Api-Version': apiVersion } : {}),
        };
        options.headers = { ...headers, ...(options.headers || {}) };
        return this.http.post<T>(this.baseUrl + url, payload, options);
      })
    );
  }

  /**
   * a wrapper for the httpClient's PATCH method
   * - sets some basic headers
   * @param url the part of the url after the baseUrl
   * @param payload the data to be sent to the backend
   * @param options object containing extra HTTP Headers or params
   * @param apiVersion usually the same for all apis defaults to "1"
   */
  // to support multiple types of payloads, we use "any" here
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  patch<T>(url: string, payload: any, options: HttpOptions = {}, apiVersion?: string): Observable<T> {
    return this.getToken().pipe(
      switchMap((idToken) => {
        const headers = {
          ...this.getBaseHeaders(idToken),
          ...this.getPostHeaders(),
          ...(apiVersion ? { 'Api-Version': apiVersion } : {}),
        };
        options.headers = { ...headers, ...(options.headers || {}) };
        return this.http.patch<T>(this.baseUrl + url, payload, options);
      })
    );
  }

  /**
   * a wrapper for the httpClient's PUT method
   * - sets some basic headers
   * @param url the part of the url after the baseUrl
   * @param payload the data to be sent to the backend
   * @param options object containing extra HTTP Headers or params
   * @param apiVersion usually the same for all apis defaults to "1"
   */
  // to support multiple types of payloads, we use "any" here
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  put<T>(url: string, payload: any, options: HttpOptions = {}, apiVersion?: string): Observable<T> {
    return this.getToken().pipe(
      switchMap((idToken) => {
        const headers = {
          ...this.getBaseHeaders(idToken),
          ...this.getPostHeaders(),
          ...(apiVersion ? { 'Api-Version': apiVersion } : {}),
        };
        options.headers = { ...headers, ...(options.headers || {}) };
        return this.http.put<T>(this.baseUrl + url, payload, options);
      })
    );
  }

  delete<T>(url: string, options: HttpOptions = {}, apiVersion?: string) {
    return this.getToken().pipe(
      switchMap((idToken) => {
        const headers = {
          ...this.getBaseHeaders(idToken),
          ...this.getPostHeaders(),
          ...(apiVersion ? { 'Api-Version': apiVersion } : {}),
        };
        options.headers = { ...headers, ...(options.headers || {}) };
        return this.http.delete<T>(this.baseUrl + url, options);
      })
    );
  }

  private getBaseHeaders(idToken: string): { [header: string]: string | string[] } {
    if (!idToken) return {};

    if (this.subscriptionKey) return { Authorization: `Bearer ${idToken}`, 'Ocp-Apim-Subscription-Key': this.subscriptionKey };

    return { Authorization: `Bearer ${idToken}` };
  }

  private getPostHeaders(): { [header: string]: string | string[] } {
    return {
      'Content-Type': 'application/json',
      'Ocp-Apim-Trace': 'true',
    };
  }

  public getAccessToken(): string {
    return this._idToken.getValue();
  }

  private getToken(): Observable<string> {
    return this.afterRefreshed$.pipe(
      filter((refreshing) => !refreshing), // only proceed once the token is refreshed
      first(),
      concatMap(() => {
        return this.expirationTime$.pipe(
          filter((expiratioTime) => {
            const tokenExpSeconds = expiratioTime - new Date().getTime() / 1000;
            return tokenExpSeconds >= 180;
          }),
          first(),
          concatMap(() => {
            return this.idToken$.pipe(
              filter((token: string) => token !== ''),
              first() // make sure if the token refreshes - that the call doesn't happen again
            );
          })
        );
      })
    );
  }
}
