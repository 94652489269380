import { Router } from '@angular/router';
import { Component, DestroyRef, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { ColumnDefinition, ComplexDialogEmbeddedView, FilterTableSettings, TableServiceV2 } from '@gea/digital-ui-lib';
import { skip, skipWhile, tap } from 'rxjs';
import { Store } from '@ngxs/store';

import { Organization } from '@shared/models';
import { FilterOrganizations, OrganizationsState } from '@shared/state';
import { customerOverviewColumnDefinitions } from '@customer-overview/table-config/customer-overview-column-definitions.config';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'gea-hrt-customer-overview',
  templateUrl: './customer-overview.component.html',
  styleUrls: ['./customer-overview.component.scss'],
})
export class CustomerOverviewComponent implements OnInit, ComplexDialogEmbeddedView {
  public loading = true;
  public columns: ColumnDefinition[] = customerOverviewColumnDefinitions;
  public organizations?: Organization[];
  public readonly TABLE_ID = 'customer-overview-table';

  isAcceptDisabled?: boolean;
  onAcceptClick?: () => void;
  onRejectClick?: () => void;

  constructor(
    public router: Router,
    public translate: TranslateService,
    public store: Store,
    public tableService: TableServiceV2,
    private destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    this.onSearchInputChange(''); // is needed to reset the search if component is mounted again
    this.store
      .select(OrganizationsState.filteredOrganizations)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        skip(1),
        skipWhile((data, index) => {
          // skip the first result (initial client side value), but only if it is empty
          return index == 0 && data.length == 0;
        }),
        tap(() => {
          this.loading = false;
        })
      )
      .subscribe((organizations) => (this.organizations = organizations));

    this.tableService
      .getFilterTableSettings(this.TABLE_ID)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((tableFilter) => {
        this.sortOrganizations(tableFilter);
      });
  }

  onSearchInputChange($event: string) {
    this.store.dispatch(new FilterOrganizations($event));
  }

  sortOrganizations(tableFilter: FilterTableSettings) {
    const sortDirection = tableFilter.columns['name']?.sort;
    if (!sortDirection) return;

    if (this.organizations) {
      this.organizations = [...this.organizations].sort((a, b) =>
        sortDirection === 'asc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name)
      );
    }
  }
}
