import { CanActivateFn, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';
import { inject } from '@angular/core';
import { PermissionState } from '@shared/state';
import { Store } from '@ngxs/store';
import { Permission } from '@shared/models/permission.model';

export const PermissionsGuard: CanActivateFn = ():
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree => {
  return inject(Store)
    .select(PermissionState.permission)
    .pipe(map((permission: Permission) => permission === 'admin'));
};
