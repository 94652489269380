<ng-container>
  <geaui-button-v2
    *ngIf="recommendation.reviewStatus === 'useful'"
    [type]="'primary'"
    data-testid="btn-save"
    (click)="setRecommendationUseful.emit(recommendation)">
    {{ 'TROUBLESHOOTING.RECOMMENDATIONS.BUTTON_SAVE' | translate }}
  </geaui-button-v2>
  <geaui-button-v2 [type]="'secondary'" data-testid="btn-edit" (click)="openEditRecommendationDialog.emit(recommendation)">
    <mat-icon svgIcon="pencil"></mat-icon>
    <span>
      {{ 'TROUBLESHOOTING.RECOMMENDATIONS.BUTTON_EDIT' | translate }}
    </span>
  </geaui-button-v2>
  <geaui-button-v2
    [type]="'secondary'"
    data-testid="btn-delete"
    (click)="openDeleteRecommendationDialog.emit(recommendation)"
    class="delete-button">
    <mat-icon svgIcon="trash-can"></mat-icon>
    <span class="label">
      {{ 'TROUBLESHOOTING.RECOMMENDATIONS.BUTTON_DELETE' | translate }}
    </span>
  </geaui-button-v2>
</ng-container>
