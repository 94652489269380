<div class="system-health-check" class="system-health-check">
  <div class="header" [class]="computedClass()">
    <div class="name">
      <b>{{ data.name | toTitle }}</b>
    </div>
    <div class="icon">
      <container-element [ngSwitch]="data.status">
        <mat-icon *ngSwitchCase="0" svgIcon="check_white"></mat-icon>
        <mat-icon *ngSwitchCase="1" svgIcon="warning_triangle"></mat-icon>
        <mat-icon *ngSwitchCase="2" svgIcon="warning_round"></mat-icon>
        <mat-icon *ngSwitchDefault svgIcon="check_white"></mat-icon>
      </container-element>
    </div>
  </div>
  <div class="metadata" *ngFor="let meta of meta">
    <div class="key">{{ meta[0] | toTitle }}</div>
    <div class="value">{{ meta[1] | number: '.1-2' }}</div>
  </div>
  <div class="footer">
    <span class="resolve-button" [class]="computedClass()" data-testid="resolve-button" (click)="openDetailsPopUp()"
      >Show Details -></span
    >
  </div>
</div>
