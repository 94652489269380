import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RecommendationDto } from '@shared/models';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Store } from '@ngxs/store';
import { ReorderRecommendations } from '@shared/state';
import { SnackbarService } from '@gea/digital-ui-lib';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'gea-hrt-recommendation-order-list',
  templateUrl: './recommendation-order-list.component.html',
  styleUrls: ['./recommendation-order-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecommendationOrderListComponent implements OnInit {
  @Output()
  orderFinished = new EventEmitter();
  @Input()
  list: RecommendationDto[] = [];

  listForOrdering: RecommendationDto[] = [];

  constructor(
    private readonly store: Store,
    private readonly snackBar: SnackbarService,
    private readonly translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.listForOrdering = [...this.list];
  }

  drop(event: CdkDragDrop<RecommendationDto[]>) {
    moveItemInArray(this.listForOrdering, event.previousIndex, event.currentIndex);
  }

  save() {
    this.store.dispatch(new ReorderRecommendations(this.listForOrdering));
    this.snackBar.add({
      detail: this.translate.instant('TAB_CONTAINER.FAULT_RECOMMENDATIONS.RECOMMENDATION_ORDER_SUCCESS'),
      summary: this.translate.instant('TAB_CONTAINER.FAULT_RECOMMENDATIONS.RECOMMENDATION_ORDER_SUCCESS'),
      severity: 'success',
    });
    this.orderFinished.emit();
  }

  cancel() {
    this.orderFinished.emit();
  }

  trackById(_: number, item: RecommendationDto) {
    return item.id;
  }
}
