import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MeasurementSerieDto } from '@shared/models';
import { cssVar } from '@shared/helpers';
import { ChartData, ChartSerie } from './data-chart';

type MeasurementsConfig = Record<string, { color: string; yAxisPosition: 'left' | 'right' }>;

// @todo replace with real keys, maybe move to config/service
const measurementsConfig: MeasurementsConfig = {
  'Pending Fault': { color: cssVar('--geaui-brighter-red'), yAxisPosition: 'left' },
  'Suction Pressure': { color: '#2F80ED', yAxisPosition: 'left' },
  'Discharge temperature': { color: '#2F80ED', yAxisPosition: 'left' },
  'Oil Pressure': { color: '#B0DDAA', yAxisPosition: 'left' },
  pressure: { color: '#B0DDFF', yAxisPosition: 'left' },
  Speed: { color: 'black', yAxisPosition: 'right' },
  Amperage: { color: 'gray', yAxisPosition: 'right' },
  temperature: { color: 'red', yAxisPosition: 'right' },
  'Discharge Temperature': { color: 'red', yAxisPosition: 'right' },
  'Motor Speed': { color: 'green', yAxisPosition: 'right' },
  'Motor Current': { color: 'blue', yAxisPosition: 'right' },
  'Saturated Suction Temperature': { color: '#fd7e14', yAxisPosition: 'right' },
  'Saturated Discharge Temperature': { color: '#6f42c1', yAxisPosition: 'right' },
};

const measurementTypeMapping: { [key: string]: string } = {
  temperature: 'Discharge Temperature',
  pressure: 'Suction Pressure',
  motor_speed: 'Motor Speed',
  motor_current: 'Motor Current',
  saturated_suction_temperature: 'Saturated Suction Temperature',
  saturated_discharge_temperature: 'Saturated Discharge Temperature',
};

@Component({
  selector: 'gea-hrt-related-operating-data',
  templateUrl: './related-operating-data.component.html',
  styleUrls: ['./related-operating-data.component.scss'],
})
export class RelatedOperatingDataComponent {
  switches: [string, boolean][] = [];
  chartData: ChartData = { series: [] };
  data: MeasurementSerieDto[] = [];
  defaultSelection = ['Discharge Temperature', 'Suction Pressure'];
  loading = false;

  @Input({ required: true })
  set measurements(measurements: MeasurementSerieDto[] | null) {
    if (measurements === null) {
      return;
    }

    this.loading = false;

    this.data = measurements.map((temp) => ({
      ...temp,
      measurementType: measurementTypeMapping[temp.measurementType.toLowerCase()] || temp.measurementType,
    }));

    this.switches = this.data.reduce(
      (acc, curr) => {
        const key = curr.measurementType;

        return [...acc, [key, this.defaultSelection.includes(key)]];
      },
      [] as [string, boolean][]
    );

    this.generateChart(this.defaultSelection);
  }

  @Output() setDateRange = new EventEmitter<number | null>();

  generateChart(types: string[]) {
    const filteredMeasurements = this.data.filter(({ measurementType }) => types.includes(measurementType));

    this.chartData =
      filteredMeasurements.length === 0
        ? { series: [] }
        : {
            series: this.generateSeries(filteredMeasurements),
          };
  }

  private generateSeries(data: MeasurementSerieDto[]) {
    let leftOffset = 0;
    let rightOffset = 0;

    return data.map(({ measurementType, dataPoints, measurementUnit }) => {
      const yAxisPosition = measurementsConfig[measurementType].yAxisPosition ?? 'left';
      const config: ChartSerie = {
        name: measurementType,
        color: measurementsConfig[measurementType].color ?? 'black',
        data: dataPoints.map(({ timestamp, value }) => [timestamp, Number(value)]),
        legendLabel: measurementType,
        yAxisName: measurementUnit,
        yAxisPosition: yAxisPosition,
        yAxisOffset: yAxisPosition === 'left' ? leftOffset : rightOffset,
        xValues: dataPoints.map(({ timestamp }) => timestamp),
      };

      if (yAxisPosition === 'left') {
        leftOffset += 50;
      } else {
        rightOffset += 50;
      }

      return config;
    });
  }

  dateRangeChanged(dateRange: number | null) {
    this.setDateRange.emit(dateRange);
  }
}
