<div class="recommendation-item">
  <div class="container">
    <mat-icon *ngIf="isDraggable" svgIcon="scroll-vertical" class="images-button" />
    <div class="text">
      <ng-container *ngIf="index !== undefined">
        <b>{{ index + 1 }}</b
        >.
      </ng-container>
      {{ recommendation.actionName }}
    </div>
    <ng-container *ngIf="recommendation.usageCount">
      <span class="usage-count">({{ recommendation.usageCount }})</span>
    </ng-container>

    <div class="actions">
      <mat-icon
        *ngIf="recommendation.images.length"
        svgIcon="image"
        class="images-button"
        (click)="openImagesDialog.emit(recommendation.images)" />

      <ng-container *ngIf="!isDraggable" [ngSwitch]="isEditable">
        <gea-hrt-recommendation-editable-actions
          *ngSwitchCase="true"
          [recommendation]="recommendation"
          (setRecommendationUseful)="setRecommendationUseful.emit(recommendation)"
          (openEditRecommendationDialog)="openEditRecommendationDialog.emit(recommendation)"
          (openDeleteRecommendationDialog)="openDeleteRecommendationDialog.emit(recommendation)" />
        <gea-hrt-recommendation-readonly-actions
          *ngSwitchCase="false"
          [recommendation]="recommendation"
          (actionClicked)="actionClicked.emit($event)" />
      </ng-container>
    </div>
  </div>
</div>
