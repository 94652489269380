import { Injectable } from '@angular/core';

import { HrtApiService } from './hrt-api.service';
import { Organization } from '@shared/models';

@Injectable({
  providedIn: 'root',
})
export class OrganizationsApiService {
  private organizationsUrl = 'organizations';

  constructor(private api: HrtApiService) {}

  public fetchOrganizations() {
    return this.api.get<Organization[]>(this.organizationsUrl);
  }

  public updateMachineNames(organizationId: number, machineNames: { id: number; name: string }[]) {
    return this.api.put<void>(`${this.organizationsUrl}/${organizationId}/machines`, machineNames);
  }
}
