<div class="health-check-dialog-container" [class]="computedClass()">
  <div class="dialog-header" [class]="computedClass()">
    <gea-hrt-heading class="title" type="sub">
      <h2>{{ data.name | toTitle }}</h2>
    </gea-hrt-heading>
    <gea-hrt-close-dialog-button (closeDialog)="closeDialog()" />
    <container-element [ngSwitch]="data.status">
      <mat-icon *ngSwitchCase="0" svgIcon="check_white"></mat-icon>
      <mat-icon *ngSwitchCase="1" svgIcon="warning_triangle"></mat-icon>
      <mat-icon *ngSwitchCase="2" svgIcon="warning_round"></mat-icon>
      <mat-icon *ngSwitchDefault svgIcon="check_white"></mat-icon>
    </container-element>
  </div>
  <mat-dialog-content class="content">
    <ul>
      <li class="flex">
        <div class="key">Start Timestamp</div>
        <div class="value"><gea-hrt-update-time [date]="data.startTimestamp" /></div>
      </li>
      <li class="flex">
        <div class="key">End Timestamp</div>
        <div class="value"><gea-hrt-update-time [date]="data.endTimestamp" /></div>
      </li>
      <li class="flex" *ngFor="let meta of metadata">
        <div class="key">{{ meta[0] | toTitle }}</div>
        <div class="value">{{ meta[1] | number: '.1-2' }}</div>
      </li>
    </ul>
    <div class="hints">Hint: {{ data.hints }}</div>
  </mat-dialog-content>
</div>
