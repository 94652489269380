<div class="image-preview-container">
  <div class="button" (click)="fileInput.click()" *ngIf="!images.length">
    <mat-icon svgIcon="image" class="add-images-button" />
    {{ 'TAB_CONTAINER.FAULT_RECOMMENDATIONS.DIALOG.ADD_IMAGES' | translate }}
  </div>
  <input
    #fileInput
    hidden
    type="file"
    id="fileInput"
    multiple
    value="Select"
    accept="image/*"
    title="Add image"
    (change)="onFileSelected($event)" />

  <div class="flex">
    <div *ngFor="let image of images; let i = index; trackBy: trackByIndex" class="image-preview">
      <img [src]="image" alt="Preview" class="preview-image" />
      <button class="delete-button" (click)="onDeleteImage(i)" title="Delete image">x</button>
    </div>
    <div *ngIf="images.length" class="add-button" (click)="onAddImage()">
      <b>+</b>
    </div>
  </div>
</div>
