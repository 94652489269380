import { Injectable } from '@angular/core';

import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { HrtApiService } from './hrt-api.service';
import { MachineAndFaultDto, OrganizationsStateModel } from '@shared/models';

@Injectable({
  providedIn: 'root',
})
export class MachinesApiService {
  machinesUrl = 'machines';

  @Select((state: { organizations: OrganizationsStateModel }) => state?.organizations?.selected?.id || null)
  organizationId$?: Observable<number>;

  constructor(private api: HrtApiService) {}

  fetchMachines(): Observable<MachineAndFaultDto[]> {
    return this.api.getForOrganization<MachineAndFaultDto[]>(this.machinesUrl);
  }
}
