import { Inject, Injectable } from '@angular/core';
import { Action, Select, Selector, State, StateContext } from '@ngxs/store';
import { Permission, PermissionStateModel } from '@shared/models/permission.model';
import { MembershipsState } from '@shared/state/memberships/memberships.state';
import { OrganizationsState } from '@shared/state/organizations/organizations.state';
import { SetUserPermissionForSelectedOrganisation } from '@shared/state/permission/permission.action';
import { first, map, Observable, of, switchMap, tap } from 'rxjs';
import { Organization } from '@shared/models';
import { Membership } from '@gea/digital-ui-lib';

@State<PermissionStateModel>({
  name: 'permission',
  defaults: {
    permission: 'customer',
  },
})
@Injectable()
export class PermissionState {
  @Select(MembershipsState.memberships) memberships$!: Observable<Membership[]>;
  @Select(OrganizationsState.selected) selectedOrganization$?: Observable<Organization>;

  constructor(@Inject('isAdminInLocal') protected isAdminInLocal: boolean) {}

  @Selector()
  static permission(): Permission {
    return 'admin';
  }

  @Action(SetUserPermissionForSelectedOrganisation)
  setPermissionForOrganisation({ patchState }: StateContext<PermissionStateModel>) {
    this.memberships$
      .pipe(
        switchMap((memberships) => this.getPermissionForSelectedOrganisation(memberships)),
        tap((permission) => patchState({ permission })),
        first()
      )
      .subscribe();
  }

  getPermissionForSelectedOrganisation(memberships: Membership[]): Observable<Permission> {
    return (
      this.selectedOrganization$?.pipe(
        map((organization) => {
          const membership = memberships.find((m) => m.organizationName === organization.name);
          const roleName = membership?.roleName ?? '';
          return this.createPermission(roleName);
        })
      ) ?? of('customer')
    );
  }

  createPermission(roleName: string): Permission {
    if (this.isAdminInLocal) return 'admin';

    switch (roleName) {
      case 'GEA_App_Administrator':
      case 'GEA_App_Operator':
        return 'admin';
      case 'admin':
        return 'customerAdmin';
      case 'gea_read_only':
        return 'customer';
      case 'maintenance_manager':
        return 'maintenance_manager';
      case 'technician':
        return 'technician';
      case 'executive_board':
        return 'executive_board';
      default:
        return 'customer';
    }
  }
}
