import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'gea-hrt-recommendation-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class RecommendationDialogComponent {
  constructor(public dialogRef: MatDialogRef<RecommendationDialogComponent>) {}

  formGroup = new FormGroup({
    recommendation: new FormControl('', [Validators.minLength(10), Validators.required]),
    images: new FormControl<(string | ArrayBuffer[])[]>([]),
  });

  save() {
    const value = this.formGroup.value;
    const actionName = value.recommendation;
    const images = value.images?.map((image) => (image as string).split('data:image/png;base64,').pop()) ?? [];

    this.dialogRef.close({
      actionName,
      images,
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onFileSelected(event: Event) {
    const inputElement = event.target as HTMLInputElement;

    Array.from(inputElement?.files ?? []).forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        const imagesControl = this.formGroup.get('images');

        if (!imagesControl) {
          return;
        }

        const images = imagesControl.value;

        // @ts-expect-error Type '(string[] | ArrayBuffer[])[] | null' must have a '[Symbol.iterator]()' method that returns an iterator.ts(2488)
        imagesControl.setValue([...images, reader.result]);
      };

      reader.readAsDataURL(file);
    });
  }

  addMoreImages() {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  }

  deleteImage(index: number) {
    const imagesControl = this.formGroup.get('images') as FormControl;
    const images = imagesControl.value as string[];
    images.splice(index, 1);
    imagesControl.setValue(images);

    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
  }
}
