<div class="fault-recommendations-dialog-container">
  <div mat-dialog-title class="recommendation-dialog-title">
    <gea-hrt-heading class="dialog-title" type="sub"
      >{{ 'TAB_CONTAINER.FAULT_RECOMMENDATIONS.DIALOG.TITLE' | translate }}
    </gea-hrt-heading>
    <gea-hrt-close-dialog-button (closeDialog)="closeDialog()" />
  </div>
  <mat-dialog-content>
    <div class="input">
      <div>{{ 'TAB_CONTAINER.FAULT_RECOMMENDATIONS.DIALOG.RECOMMENDATION' | translate }}</div>
      <geaui-textfield-v2 [id]="'recommendation'" [required]="false" [group]="formGroup"></geaui-textfield-v2>
    </div>

    <div class="image">
      <gea-hrt-images-preview
        [images]="formGroup.get('images')?.value ?? []"
        (deleteImage)="deleteImage($event)"
        (addImage)="addMoreImages()"
        (fileSelected)="onFileSelected($event)" />
    </div>

    <div class="buttons">
      <geaui-button-v2 [type]="'secondary'" (click)="closeDialog()">
        {{ 'UI-LIB.GENERAL.CANCEL' | translate }}
      </geaui-button-v2>
      <geaui-button-v2 id="submit-button" data-testid="button-save" [disabled]="!formGroup.valid" (click)="save()">
        {{ 'UI-LIB.GENERAL.SAVE' | translate }}
      </geaui-button-v2>
    </div>
  </mat-dialog-content>
</div>
