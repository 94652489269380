import { ColumnDefinition, ColumnRendererComponent } from '@gea/digital-ui-lib';
import { Shutdown, Warning } from '@shared/models';
import {
  LastInstanceRendererComponent,
  TextSolvedRenderComponent,
  LinkButtonRendererComponent,
  ProgressBarRendererComponent,
} from './renderer';

export const Fix_ACTION = 'fix-action';

interface ColumnConfig {
  totalCountColor: string;
  displayNamePercentage: string;
}

const createColumnDefinitions = (config: ColumnConfig): ColumnDefinition[] => [
  {
    key: 'machineName',
    displayName: 'FAULT_ANALYSIS.COMMON_TABLE_HEADERS.MACHINE',
  },
  {
    key: 'faultName',
    displayName: 'FAULT_ANALYSIS.COMMON_TABLE_HEADERS.FAULT_NAME',
  },
  {
    key: 'timeStamp',
    displayName: 'FAULT_ANALYSIS.COMMON_TABLE_HEADERS.LAST_INSTANCE',
    renderer: {
      component: LastInstanceRendererComponent as ColumnRendererComponent<Warning | Shutdown>,
    },
  },
  {
    key: 'countPending',
    displayName: 'FAULT_ANALYSIS.COMMON_TABLE_HEADERS.PENDING_COUNT',
  },
  {
    key: 'countSolved',
    displayName: 'FAULT_ANALYSIS.COMMON_TABLE_HEADERS.SOLVED_COUNT',
    renderer: {
      component: TextSolvedRenderComponent as ColumnRendererComponent<Warning | Shutdown>,
    },
  },
  {
    key: 'countPendingRelative',
    displayName: config.displayNamePercentage,
    renderer: {
      component: ProgressBarRendererComponent as ColumnRendererComponent<Warning | Shutdown>,
      config: {
        barHeight: 20,
        countColor: config.totalCountColor,
        totalCountColor: 'var(--geaui-light-gray)',
      },
    },
  },
  {
    key: 'actionBtn',
    displayName: '',
    renderer: {
      component: LinkButtonRendererComponent as ColumnRendererComponent<Warning | Shutdown>,
    },
  },
];

export const shutdownDefinitions = createColumnDefinitions({
  totalCountColor: 'var(--geaui-red)',
  displayNamePercentage: 'FAULT_ANALYSIS.SHUTDOWNS.TABLE_HEADERS.TOTAL_PERCENTAGE',
});

export const warningDefinitions = createColumnDefinitions({
  totalCountColor: 'var(--geaui-yellow)',
  displayNamePercentage: 'FAULT_ANALYSIS.WARNINGS.TABLE_HEADERS.TOTAL_PERCENTAGE',
});
