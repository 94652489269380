<div class="tab-container">
  <div class="flex">
    <div class="side-bar">
      <geaui-sidebar-v2
        *ngIf="organizationId"
        [devisionIconBackgroundColor]="'var(--geaui-night-blue)'"
        [devisionName]="'GENERAL.TITLE' | translate"
        [navigationItems]="navigationItems"
        [bottomNavigation]="[
          {
            key: 'contact',
            icon: '16px_chat',
            label: 'CONTACT_TEXT',
            type: SidepanelTypes.CONTACT,
          },
          {
            key: 'about',
            icon: '16px_c-info',
            label: 'NAV-BAR.ABOUT',
            type: SidepanelTypes.FOOTER,
            ref: AboutPanelComponent,
            inputs: {
              copyright: 'GEA Group Services GmbH 2024',
              footers: [
                {
                  key: 'imprint-header',
                  header: 'UI-LIB.FOOTER.IMPRINT',
                  content: [
                    {
                      key: 'imprint',
                      icon: '24px_pdf',
                      label: 'UI-LIB.FOOTER.IMPRINT',
                      action: this.openImprint.bind(this),
                    },
                  ],
                },
                {
                  key: 'privacy-header',
                  header: 'UI-LIB.FOOTER.DATAPRIVACY',
                  content: [
                    {
                      key: 'privacy',
                      icon: '24px_pdf',
                      label: 'UI-LIB.FOOTER.DATAPRIVACY',
                      action: this.openDataPrivacy.bind(this),
                    },
                  ],
                },
                {
                  key: 'terms-header',
                  header: 'UI-LIB.FOOTER.TERMS-AND-CONDITIONS',
                  content: [
                    {
                      key: 'terms',
                      icon: '24px_pdf',
                      label: 'UI-LIB.FOOTER.TERMS-AND-CONDITIONS',
                      action: this.openTermsAndConditions.bind(this),
                    },
                  ],
                },
                {
                  key: 'cookie-settings',
                  header: 'UI-LIB.FOOTER.COOKIE-SETTINGS',
                  content: [
                    {
                      icon: '',
                      key: 'cookies',
                      label: 'UI-LIB.FOOTER.COOKIES',
                      action: this.openCookieSettings.bind(this),
                    },
                  ],
                },
              ],
            },
          },
        ]">
        <a href="mailto:digital.operation.centre@gea.com?subject=Regarding BLU-RED-Care:">
          <mat-icon svgIcon="mail" class="icon-mail" />
          <span class="contact-text">{{ 'CONTACT_TEXT' | translate }}</span>
        </a>
      </geaui-sidebar-v2>
    </div>
    <div class="tab-content">
      <div class="tab-container-header">
        <a href="mailto:digital.operation.centre@gea.com?subject=Regarding BLU-RED-Care:">
          <mat-icon svgIcon="mail" class="icon-mail" />
          <span class="contact-text">{{ 'CONTACT_TEXT' | translate }}</span>
        </a>
        <div *ngIf="organization$ | async as organization" class="selected-customer">
          <h3>{{ organization.name }}</h3>
        </div>
        <geaui-button-v2 (click)="openCustomerOverview()">{{ 'BUTTON_OPEN_CUSTOMER_OVERVIEW' | translate }}</geaui-button-v2>
      </div>
      <ng-container *ngIf="organization$ | async as organization; else noOrganization">
        <router-outlet></router-outlet>
      </ng-container>
    </div>
  </div>

  <div class="tab-content-no-customer">
    <ng-template #noOrganization>
      <gea-hrt-hint>{{ 'HINT.NO_CUSTOMER' | translate }}</gea-hrt-hint>
    </ng-template>
  </div>
</div>
