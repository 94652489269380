import { ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ApiErrorResponse, ErrorHandlerV2Service } from '@gea/digital-ui-lib';
import { catchError, first, of } from 'rxjs';

import { ClearRecommendations } from '@shared/state/recommendations';
import { ClearMachines, FetchMachines } from '@shared/state/machines';
import { OrganizationsApiService } from '@shared/services';
import { OrganizationsComparator } from './organizations.comparator';
import { FetchOrganizations, FilterOrganizations, SetSelectedOrganization, SortOrganizations } from './organizations.action';
import { Organization, OrganizationsStateModel } from '@shared/models';
import { SetUserPermissionForSelectedOrganisation } from '@shared/state/permission/permission.action';

@State<OrganizationsStateModel>({
  name: 'organizations',
  defaults: {
    organizations: [],
    filteredOrganizations: [],
    selected: null,
  },
})
@Injectable()
export class OrganizationsState {
  constructor(
    private organizationsApi: OrganizationsApiService,
    private route: ActivatedRoute,
    private errorHandler: ErrorHandlerV2Service
  ) {}

  @Selector()
  static organizations(state: OrganizationsStateModel): Organization[] {
    return state.organizations;
  }

  @Selector()
  static filteredOrganizations(state: OrganizationsStateModel): Organization[] {
    return state.filteredOrganizations;
  }

  @Selector()
  static selected(state: OrganizationsStateModel): Organization | null {
    return state.selected;
  }

  @Selector()
  static selectedId(state: OrganizationsStateModel): number | null {
    if (state && state.selected) {
      return state.selected.id;
    } else {
      return null;
    }
  }

  @Action(FetchOrganizations)
  fetchOrganizations({ patchState, dispatch }: StateContext<OrganizationsStateModel>) {
    this.organizationsApi
      .fetchOrganizations()
      .pipe(
        catchError((error: ApiErrorResponse) => {
          if (error) {
            this.errorHandler.handleError(error);
          }
          return of([]);
        }),
        first()
      )
      .subscribe((organizations) => {
        patchState({
          organizations,
          filteredOrganizations: organizations,
        });
        const snapshot = this.route.firstChild?.firstChild?.snapshot;
        const organizationId = parseInt(snapshot?.params.organizationId as string);
        const organization = organizations.find(
          (_organization: Organization) => _organization.id === organizationId
        ) as Organization;
        if (organization) {
          dispatch(new SetSelectedOrganization(organization));
        }
      });
  }

  @Action(SetSelectedOrganization)
  setSelectedOrganization({ patchState, dispatch }: StateContext<OrganizationsStateModel>, action: SetSelectedOrganization) {
    if (action.organization) {
      patchState({
        selected: action.organization,
      });
      dispatch(new ClearMachines());
      dispatch(new FetchMachines());
      dispatch(new ClearRecommendations());
      dispatch(new SortOrganizations('faults', 'desc', action.organization.id)); // reset sorting on customer selection
      dispatch(new SetUserPermissionForSelectedOrganisation());
    }
  }

  @Action(SortOrganizations)
  sortOrganizations({ patchState, getState }: StateContext<OrganizationsStateModel>, action: SortOrganizations) {
    let customerList = [...getState().filteredOrganizations];
    customerList.sort(OrganizationsComparator.by(action.columName, action.sortOrder));

    customerList = this.moveSelectedOrganizationToTopOfList(customerList, action.organizationId);

    if (customerList) {
      patchState({
        filteredOrganizations: customerList,
      });
    }
  }

  @Action(FilterOrganizations)
  filterCustomer({ patchState, getState }: StateContext<OrganizationsStateModel>, action: FilterOrganizations) {
    const customerList = [...getState().organizations];
    let filteredOrganizations = customerList.filter((customer: Organization) => {
      return customer.name.toLowerCase().indexOf(action.filterString.toLowerCase()) !== -1;
    });

    filteredOrganizations = this.moveSelectedOrganizationToTopOfList(filteredOrganizations, getState().selected?.id);

    if (filteredOrganizations) {
      patchState({
        filteredOrganizations,
      });
    }
  }

  private moveSelectedOrganizationToTopOfList(organizations: Organization[], organizationId: number | undefined): Organization[] {
    if (!organizationId) {
      return organizations;
    }

    const selected = organizations.find((org) => org.id === organizationId);
    if (selected) {
      return [selected, ...organizations.filter((org) => org.id !== selected.id)];
    }

    return organizations;
  }
}
