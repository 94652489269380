<div class="hrt-related-operating-data-header">
  <gea-hrt-ts-date-range (hoursChanged)="dateRangeChanged($event)" />
  <gea-hrt-history-chart-toggle
    class="toggle-buttons"
    [switches]="switches"
    [defaultSelection]="defaultSelection"
    (updateSelection)="generateChart($event)" />
</div>
<div class="hrt-related-operating-data-content">
  <ng-container *ngIf="!loading; else Loading">
    <gea-hrt-data-chart *ngIf="chartData.series.length; else NoChartData" [data]="chartData" />
  </ng-container>
  <ng-template #Loading>
    <div class="spinner" class="alternative-spinner">
      <mat-spinner [diameter]="100" color="green" [strokeWidth]="10" />
      <p>{{ 'TROUBLESHOOTING.RELATED_OPERATING_DATA.LONG_LOADING_TEXT' | translate }} ...</p>
    </div>
  </ng-template>
  <ng-template #NoChartData>
    <gea-hrt-empty-data-placeholder text="any chart data" />
  </ng-template>
</div>
