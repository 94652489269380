import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RecommendationDto } from '@app/shared/models';
import { RecommendationAction } from '@app/tab-container/troubleshooting';

@Component({
  selector: 'gea-hrt-recommendation-item',
  templateUrl: './recommendation-item.component.html',
  styleUrl: './recommendation-item.component.scss',
})
export class RecommendationItemComponent {
  @Input({ required: true }) recommendation!: RecommendationDto;
  @Input() index?: number;
  @Input() isEditable = false;
  @Input() isDraggable = false;

  @Output() openImagesDialog = new EventEmitter<(ArrayBuffer[] | string)[]>();
  @Output() openDeleteRecommendationDialog = new EventEmitter<RecommendationDto>();
  @Output() actionClicked = new EventEmitter<RecommendationAction>();
  @Output() openEditRecommendationDialog = new EventEmitter<RecommendationDto>();
  @Output() setRecommendationUseful = new EventEmitter<RecommendationDto>();
}
