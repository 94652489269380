import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FiltersBaseComponentComponent } from '@app/shared/components';
import { LayoutService, SelectOption } from '@gea/digital-ui-lib';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'gea-hrt-ts-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class TroubleshootingFiltersComponent extends FiltersBaseComponentComponent {
  @Input({ required: true }) machineOptions!: SelectOption[];
  @Input({ required: true }) faultOptions!: SelectOption[];
  @Input({ required: true }) selectedFault?: number;
  @Input({ required: true }) selectedMachine?: number;

  @Output() selectMachine = new EventEmitter<number>();
  @Output() selectFaultCode = new EventEmitter<number>();

  formGroup = new FormGroup({
    machine: new FormControl(),
    faultCode: new FormControl(),
  });

  constructor(
    public layout: LayoutService,
    public translate: TranslateService
  ) {
    super(layout, translate);

    this.translateOptions();
  }

  onMachineChange(machineId: number) {
    this.selectMachine.emit(machineId);
  }

  onFaultCodeChange(faultCodeId: number) {
    this.selectFaultCode.emit(faultCodeId);
  }
}
