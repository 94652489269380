<div class="fault-recommendations-container">
  <div class="section filters">
    <ng-container *ngIf="annunciationsMetadata$ | async as metadata">
      <gea-hrt-fault-recommendations-filters
        [faultCatalog]="faultCatalog"
        [hasRecommendations]="hasRecommendations"
        [selectedFaultName]="selectedFaultName"
        [annunciationsMetaData]="metadata"
        (setSelectedFault)="faultChanged($event)"
        (setHasRecommendations)="setHasRecommendations($event)" />
    </ng-container>
  </div>
  <div class="section recommendations">
    <ng-container *ngIf="selectedFaultCode; else NoFaultSelected">
      <ng-container *ngIf="recommendations$ | async as recommendations">
        <gea-hrt-info-box
          *ngIf="recommendations.length; else NoRecommendations"
          [withBorder]="false"
          boxTitle="{{ 'TAB_CONTAINER.FAULT_RECOMMENDATIONS.RECOMMENDATIONS.TITLE' | translate }}"
          tooltip="{{ 'TAB_CONTAINER.FAULT_RECOMMENDATIONS.RECOMMENDATIONS.TOOLTIP' | translate }}">
          <ng-container *ngIf="editOrderModeOn; else NormalMode">
            <gea-hrt-recommendation-order-list
              data-testid="recommendation-order-list"
              [list]="recommendations"
              (orderFinished)="editOrderModeOn = false" />
          </ng-container>
          <ng-template #NormalMode>
            <gea-hrt-recommendations-list
              data-testid="recommendation-list"
              [isEditable]="true"
              [list]="recommendations"
              (updateRecommendation)="saveRecommendation($event)"
              (deleteRecommendation)="confirmDeleteRecommendation($event)" />
          </ng-template>
        </gea-hrt-info-box>
      </ng-container>
    </ng-container>
  </div>
  <div class="section no-data">
    <ng-template #NoRecommendations>
      <gea-hrt-hint>{{ 'TAB_CONTAINER.FAULT_RECOMMENDATIONS.NO_RECOMMENDATION' | translate }}</gea-hrt-hint>
    </ng-template>
    <br />
    <ng-template #NoFaultSelected>
      <gea-hrt-hint>{{ 'TAB_CONTAINER.FAULT_RECOMMENDATIONS.NO_FAULT_SELECTED' | translate }}</gea-hrt-hint>
    </ng-template>
  </div>
  <div class="section actions" *ngIf="selectedFaultCode">
    <geaui-button-v2 *ngIf="!editOrderModeOn" (click)="openAddRecommendationDialog()" data-testid="btn-new-recommendation">
      {{ 'TAB_CONTAINER.FAULT_RECOMMENDATIONS.BUTTON_NEW_RECOMMENDATION' | translate }}
    </geaui-button-v2>
    <div>
      <ng-container *ngIf="!editOrderModeOn">
        <geaui-button-v2
          *ngIf="recommendations$ | async as recommendations"
          data-testid="btn-edit-order"
          [type]="'secondary'"
          [disabled]="recommendations.length < 1"
          (click)="editOrderModeOn = true">
          {{ 'TAB_CONTAINER.FAULT_RECOMMENDATIONS.BUTTON_EDIT_ORDER' | translate }}
        </geaui-button-v2>
      </ng-container>
    </div>
  </div>
  <div class="section" *ngIf="resolvedFaults">
    <gea-hrt-solved-faults [data]="resolvedFaults" (setReviewStatus)="setReviewStatus($event)" />
  </div>
</div>
